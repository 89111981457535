import * as React from 'react'
import { getSiteData } from '../repository/site.repository'
import { Helmet } from 'react-helmet'
import Layout from '../components/layout'
import { getFeaturedSheets, PageSheet } from '../repository/sheet.repository'
import { flow } from 'fp-ts/function'
import * as Array from 'fp-ts/Array'
import {
	constructNavigatableItem,
	NavigatableItem
} from '../common/navigatable'
import HeroHeader from '../components/hero-header'
import FeaturedSheetsSection from '../components/featured-sheets-section'
import FeaturesSection from '../components/features-section'
import LatestNewsSection from '../components/latest-news-section'
import Footer from '../components/footer'
import { getPosts } from '../repository/post.repository'

const IndexPage = () => {
	const siteData = getSiteData()
	const sheetData = getFeaturedSheets()
	const posts = getPosts()

	return (
		<Layout
			img='/images/messi_big_big.jpg'
			siteUrl={siteData.url}
			title={siteData.title}
			subtitle={siteData.subtitle}
			navigationItems={extractNavItems(sheetData)}
		>
			<HeroHeader
				title={siteData.title}
				subtitle={siteData.subtitle}
				description={siteData.description}
				action={{ label: 'Bet Now', url: '/sheet/open-account' }}
			/>
			<FeaturedSheetsSection sheets={sheetData} />
			<FeaturesSection />
			<LatestNewsSection posts={posts} />
			<Footer />
			<Helmet>
				<title>
					{siteData.title} - {siteData.subtitle}
				</title>
			</Helmet>
		</Layout>
	)
}

const extractNavItems = flow(
	Array.map((sheet: PageSheet) => ({
		id: sheet.id,
		label: sheet.label,
		url: `/sheet/${sheet.slug}`
	})),
	Array.map(constructNavigatableItem),
	Array.prepend({ id: 'home', label: 'Home', url: '/' })
)

export default IndexPage
