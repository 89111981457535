import * as React from 'react'
import { PageSheet } from '../repository/sheet.repository'
import { Link } from 'gatsby'

interface FeaturedSheetsSectionProps {
	readonly sheets: PageSheet[]
}

const FeaturedSheetsSection = ({ sheets }: FeaturedSheetsSectionProps) => (
	<section
		id='featured-sheet-section'
		className='flex flex-wrap space-y-12 bg-gray-800 py-12 w-full lg:space-y-0 lg:p-12 2xl:mx-auto 2xl:max-w-screen-2xl'
	>
		{sheets.map(sheet => (
			<div key={sheet.id} className='w-full lg:p-4 lg:w-1/3'>
				<figure className='flex flex-col h-full lg:bg-black lg:rounded-lg lg:shadow-lg'>
					<img
						className='w-full object-cover object-center lg:rounded-t-lg lg:h-40'
						src={sheet.img}
						alt={sheet.description}
					/>
					<figcaption className='flex-grow flex flex-col'>
						<div className='flex-grow mb-4 p-4'>
							<h3 className='text-gray-400'>{sheet.label}</h3>
							<p className='text-gray-500'>{sheet.description}</p>
						</div>
						<Link
							className='btn btn-link'
							to={`/sheet/${sheet.slug}`}
						>
							Learn More
						</Link>
					</figcaption>
				</figure>
			</div>
		))}
	</section>
)

export default FeaturedSheetsSection
