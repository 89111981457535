import { graphql, useStaticQuery } from 'gatsby'
import * as Array from 'fp-ts/Array'
import * as S from 'fp-ts/string'
import { pipe } from 'fp-ts/function'
import { contramap } from 'fp-ts/Ord'

export interface Post {
	readonly id: string
	readonly slug: string
	readonly img: string
	readonly title: string
	readonly author: string
	readonly date: string
}

const byDate = pipe(
	S.Ord,
	contramap((m: Mdx) => m.frontmatter.date)
)
export const getPosts = () =>
	pipe(
		useStaticQuery<QueryResult>(query),
		q => q.allFile.nodes,
		Array.map(n => n.childMdx),
		Array.sort(byDate),
		Array.map(mdxToPost),
		xs => xs.reverse()
	)

function mdxToPost(mdx: Mdx): Post {
	return {
		id: mdx.id,
		slug: mdx.slug,
		img: mdx.frontmatter.img,
		title: mdx.frontmatter.title,
		author: mdx.frontmatter.author,
		date: mdx.frontmatter.date
	}
}

interface Mdx {
	readonly frontmatter: {
		img: string
		title: string
		author: string
		date: string
	}
	readonly id: string
	readonly slug: string
}

type QueryResult = {
	allFile: {
		nodes: {
			childMdx: Mdx
		}[]
	}
}

const query = graphql`
	query {
		allFile(filter: { sourceInstanceName: { eq: "posts" } }) {
			nodes {
				childMdx {
					frontmatter {
						img
						title
						author
						date
					}
					id
					slug
				}
			}
		}
	}
`
