import * as React from 'react'
import { Post } from '../repository/post.repository'
import { FaNewspaper } from 'react-icons/all'
import { Link } from 'gatsby'

interface LatestNewsSectionProp {
	readonly posts: Post[]
}

const LatestNewsSection = ({ posts }: LatestNewsSectionProp) => (
	<section className='bg-gray-800 flex flex-col items-center lg:p-12'>
		<div className='flex items-center mb-12'>
			<FaNewspaper className='text-gray-50' />
			<h2 className='text-3xl text-gray-50 mx-2'>Latest News</h2>
			<FaNewspaper className='text-gray-50' />
		</div>
		<div id='news-items' className='flex flex-wrap w-full'>
			{posts.map(p => (
				<Link
					key={p.id}
					className='p-4 w-full sm:w-1/2 lg:w-1/3 xl:w-1/4'
					to={`/post/${p.slug}`}
				>
					<figure className='bg-black rounded-lg shadow-md h-full'>
						<img
							className='w-full rounded-t-lg object-cover object-center'
							style={{ height: '260px' }}
							alt={p.title}
							src={p.img}
						/>
						<figcaption className='p-4'>
							<h2 className='font-medium text-lg text-gray-400'>
								{p.title}
							</h2>
							<hr className='border-gray-600 m-4' />
							<div className='flex justify-between'>
								<p className='text-yellow-400'>{p.author}</p>
								<p className='text-gray-500'>{p.date}</p>
							</div>
						</figcaption>
					</figure>
				</Link>
			))}
		</div>
	</section>
)

export default LatestNewsSection
